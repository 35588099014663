@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .block-intro {
    @apply flex justify-center flex-col text-white transition-all duration-1000 max-w-4xl p-5 py-10 lg:py-20;
    @apply bg-ettio-800;
  }

  .block-intro .headline {
    @apply pb-6;
  }

  .block-intro .headline h3 {
    @apply pb-0;
  }

  .block-intro h3 {
    @apply text-4xl pb-5;
  }

  .block-intro h4 {
    @apply text-2xl pb-3;
  }

  .block-intro h5 {
    @apply text-xl mb-5;
  }

  .block-intro h6 {
    @apply font-sans;
  }

  .block-intro ul {
    @apply pt-4 pl-3 pb-7;
  }

  .block-intro ul li {
    @apply list-disc list-outside font-sans text-sm pb-2;
  }

  .step {
    @apply flex justify-center flex-col text-white transition-all duration-1000 max-w-4xl mx-auto font-serif;
  }

  .step0 {
    @apply bg-red-200 text-opacity-0 bg-no-repeat bg-contain bg-blend-screen bg-right-bottom py-40;
  }

  .step-toc {
    @apply flex justify-center flex-col text-white transition-all duration-1000 max-w-4xl mx-auto;
    @apply bg-ettio-200 h-10 pl-5 text-gray-700 text-sm sticky top-7 z-50;
  }

  .step-toc ul {
    @apply list-none flex space-x-6;
  }

  .step-toc ul li {
    @apply list-none list-inside;
  }

  .step-toc ul li.current-tab {
    @apply font-bold;
  }


  .step1 .scrolly-0 article img {
    @apply w-48 ml-0 mb-2 mt-0;
  }

  .step1 {
    @apply text-teal-900;
  }

  .step1.step-active {
    @apply text-teal-800;
  }

  .step2 {
    @apply text-ettio-700 bg-ettio-200;
  }

  .step2.step-active {
    @apply text-ettio-900;
  }


  .step1 .scrolly-1 article img {
    @apply w-48 -ml-6 mb-2 mt-0;
  }

  article {
    @apply p-5 pb-10;
  }

  article a {
    @apply underline;
  }

  article p {
    @apply md:text-justify pt-4;
  }

  article ul {
    @apply pt-4 pb-2 pl-4;
  }

  article ul li {
    @apply list-disc list-outside pb-1 font-sans antialiased;
  }

  article h3 {
    @apply text-2xl;
  }

  article h4 {
    @apply text-lg pt-6 underline underline-offset-2;
  }

  article h5 {
    @apply text-sm pb-4 italic;
  }

  article h6 {
    @apply text-sm mt-1 italic;
  }

  figure h3 {
    @apply text-2xl;
  }

  figure h4 {
    @apply text-base;
  }

  figure h6 {
    @apply text-sm;
  }

  figure {
    top: 68px;
    @apply px-5 pb-2 pt-2 sticky bg-ettio-100 text-ettio-700 border-b border-ettio-200;
  }

  .scrolly {
    @apply bg-ettio-50 text-ettio-700;
  }

  .scrolly .logos {
    @apply mt-8;
  }

  .scrolly .logos p {
    @apply grid grid-cols-3 sm:grid-cols-3 -mt-2 m-auto max-w-xl;
  }

  .step1 .scrolly-2 article img {
    @apply m-auto mt-5;
    max-width: 100px;
  }


  .step-skills {
    @apply py-16 sm:pt-2 bg-slate-200;
  }

  .step-skills {
    @apply py-16 sm:pt-2 bg-slate-200;
  }

  .step-skills h3 {
    @apply pb-3 text-2xl;
  }

  .step-skills ul li {
    @apply list-disc list-outside ml-4 pl-2 font-sans;
  }

  .step-skills ul {
    @apply mb-6 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4;
  }

  .step-pdf {
    @apply py-20 text-center;
  }

  .step-pdf h3 {
    @apply text-sm uppercase font-sans tracking-widest mb-2;
  }

  .step-pdf h2 {
    @apply text-2xl;
  }

  .step-pdf img {
    @apply mt-5 m-auto;
  }

  .step-footer {
    @apply text-ettio-100 p-5 py-20 bg-top transition-all bg-ettio-600 text-center border-b border-ettio-500 border-t;
  }

  .step-footer h1 {
    @apply text-4xl pb-5;
  }

  .step-footer h2 {
    @apply text-2xl;
  }

}

/* h1 {
  font-size: 96px;
}
h2 {
  font-size: 54px;
}
h3 {
  font-size: 32px;
} */
